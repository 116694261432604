import React, { useState, useEffect } from 'react';
import './DashboardEmailing.css';
import config from "../../../config";
import { useAuthFetch } from "../../../hooks/useAuthFetch";

const DashboardEmailing = () => {
    const [data, setData] = useState(null);
    const authFetch = useAuthFetch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await authFetch(`${config.API_URL}/emailing/stats`);
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();
    }, [authFetch]);

    if (!data) {
        return <div>Loading...</div>;
    }

    const {
        liste_desabonnes = [],
        nombre_desabonnes = 0,
        stats_par_campagne = {},
        stats_globales = {}
    } = data;

    const critereLabels = {
        formula: "Formules",
        lang: "Langues",
        country: "Pays"
    };

    const renderCriteres = (campaign, critere) => {
        const envois = campaign.envois_par_criteres?.[critere] || {};
        const ouvertures = campaign.ouvertures_par_criteres?.[critere] || {};
        const ouvertures_uniques = campaign.ouvertures_uniques_par_criteres?.[critere] || {};
        const clics = campaign.clics_par_criteres?.[critere] || {};
        const clics_uniques = campaign.clics_uniques_par_criteres?.[critere] || {};
        const ouvertures_rate = campaign.ouvertures_rate_par_criteres?.[critere] || {};
        const ouvertures_uniques_rate = campaign.ouvertures_uniques_rate_par_criteres?.[critere] || {};
        const clics_rate = campaign.clics_rate_par_criteres?.[critere] || {};
        const clics_uniques_rate = campaign.clics_uniques_rate_par_criteres?.[critere] || {};

        return (
            <div key={critere}>
                <strong>{critereLabels[critere]}:</strong>
                <div className="critere-table">
                    <div className="critere-row critere-header">
                        <div>{critereLabels[critere]}</div>
                        <div>Envois</div>
                        {campaign.ouvertures_par_criteres && <div>Ouvertures</div>}
                        {campaign.ouvertures_uniques_par_criteres && <div>Ouvertures Uniques</div>}
                        {campaign.clics_par_criteres && <div>Clics</div>}
                        {campaign.clics_uniques_par_criteres && <div>Clics Uniques</div>}
                        {campaign.ouvertures_rate_par_criteres && <div>Taux d'Ouverture</div>}
                        {campaign.ouvertures_uniques_rate_par_criteres && <div>Taux d'Ouvertures Uniques</div>}
                        {campaign.clics_rate_par_criteres && <div>Taux de Clics</div>}
                        {campaign.clics_uniques_rate_par_criteres && <div>Taux de Clics Uniques</div>}
                    </div>
                    {Object.keys(envois).map(key => (
                        <div key={key} className="critere-row">
                            <div>{key}</div>
                            <div>{envois[key]}</div>
                            {campaign.ouvertures_par_criteres && <div>{ouvertures[key] || '-'}</div>}
                            {campaign.ouvertures_uniques_par_criteres && <div>{ouvertures_uniques[key] || '-'}</div>}
                            {campaign.clics_par_criteres && <div>{clics[key] || '-'}</div>}
                            {campaign.clics_uniques_par_criteres && <div>{clics_uniques[key] || '-'}</div>}
                            {campaign.ouvertures_rate_par_criteres && <div>{ouvertures_rate[key]?.ouvertures_rate_percent || '-'}</div>}
                            {campaign.ouvertures_uniques_rate_par_criteres && <div>{ouvertures_uniques_rate[key]?.ouvertures_uniques_rate_percent || '-'}</div>}
                            {campaign.clics_rate_par_criteres && <div>{clics_rate[key]?.clics_rate_percent || '-'}</div>}
                            {campaign.clics_uniques_rate_par_criteres && <div>{clics_uniques_rate[key]?.clics_uniques_rate_percent || '-'}</div>}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="dashboard-emailing">
            <h1>Emailing Dashboard</h1>
            <section>
                <h2>Global Stats</h2>
                <div className="stats-globales">
                    <p><strong>Envois:</strong> {stats_globales.envois || '-'}</p>
                    <p><strong>Ouvertures:</strong> {stats_globales.ouvertures || '-'}</p>
                    <p><strong>Clics:</strong> {stats_globales.clics || '-'}</p>
                    <p><strong>Ouvertures Uniques:</strong> {stats_globales.ouvertures_uniques || '-'}</p>
                    <p><strong>Taux d'Ouverture:</strong> {stats_globales.ouvertures_rate_percent || '-'}</p>
                    <p><strong>Clics Uniques:</strong> {stats_globales.clics_uniques || '-'}</p>
                    <p><strong>Taux de Clics:</strong> {stats_globales.clic_rate_percent || '-'}</p>
                    <p><strong>Taux d'Ouvertures Uniques:</strong> {stats_globales.ouvertures_uniques_rate_percent || '-'}</p>
                    <p><strong>Taux de Clics Uniques:</strong> {stats_globales.clic_rate_unique_percent || '-'}</p>
                </div>
            </section>

            <section>
                <h2>Désabonnés</h2>
                <p><strong>Nombre de désabonnés:</strong> {nombre_desabonnes}</p>
                <ul>
                    {liste_desabonnes.map((email, index) => (
                        <li key={index}>{email}</li>
                    ))}
                </ul>
            </section>

            <section>
                <h2>Stats par Campagne</h2>
                {Object.keys(stats_par_campagne).map((campaignId) => {
                    const campaign = stats_par_campagne[campaignId];
                    return (
                        <div key={campaignId} className="campaign">
                            <h3>{campaign.campaign_name}</h3>
                            <p><strong>Envois:</strong> {campaign.envois || '-'}</p>
                            <p><strong>Ouvertures:</strong> {campaign.ouvertures || '-'}</p>
                            <p><strong>Clics:</strong> {campaign.clics || '-'}</p>
                            <p><strong>Ouvertures Uniques:</strong> {campaign.ouvertures_uniques || '-'}</p>
                            <p><strong>Taux d'Ouverture:</strong> {campaign.ouvertures_rate_percent || '-'}</p>
                            <p><strong>Clics Uniques:</strong> {campaign.clics_uniques || '-'}</p>
                            <p><strong>Taux de Clics:</strong> {campaign.clic_rate_percent || '-'}</p>
                            <p><strong>Taux d'Ouvertures Uniques:</strong> {campaign.ouvertures_uniques_rate_percent || '-'}</p>
                            <p><strong>Taux de Clics Uniques:</strong> {campaign.clic_rate_unique_percent || '-'}</p>

                            <div className="criteres">
                                <h4>Données par Critères</h4>
                                {['formula', 'lang', 'country'].map(critere => renderCriteres(campaign, critere))}
                            </div>
                        </div>
                    );
                })}
            </section>
        </div>
    );
};

export default DashboardEmailing;
