const config = {
    "OPERATION_NAME":"Invest Loreal",
    "API_URL":"https://2024.invest.loreal.api.b-fly.com",
    "TINY_MCE_API_KEY":"1527x3ef5ajbywfdm25kadjmxzahw16uvrzdkfdn0sydl15y",
    "AUTH0_DOMAIN": "butterfly-henner.eu.auth0.com",
    "AUTH0_CLIENT_ID": "fBzZb7Ts4b1heRn3FCFdkJ9Qsnv7mw8J",
    "AUTH0_AUDIENCE": "https://api.invest.loreal.ecollaboration-platform.com/",
};

export default config;
