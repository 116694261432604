import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '../../../components/atoms/Button/Button';
import CodeLangList from '../../../components/organisms/CodeLangList/CodeLangList';
import config from "../../../config";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import './Campaign.css';
import Status from "../../../components/atoms/Status/Status";
import { convertToDDMMYYYY, convertToDDMMYYYYWithTime } from '../../../utils/dateUtils';
import BatchList from "../../../components/organisms/BatchList/BatchList";
import TemplatesList from "../../../components/organisms/TemplateList/TemplatesList";
import Select from "../../../components/atoms/Select/Select";
import * as XLSX from "xlsx";
import Loader from "../../../components/atoms/Loader/Loader";
import EmailPopup from "../../../components/organisms/EmailPopup/EmailPopup";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch";

const Campaign = () => {
    const [campaign, setCampaign] = useState(null);
    const [codeLangs, setCodeLangs] = useState([]);
    const [batchs, setBatchs] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selectedCodeLangs, setSelectedCodeLangs] = useState([]);
    const [savedSelections, setSavedSelections] = useState(() => {
        const saved = localStorage.getItem('savedSelections');
        return saved ? JSON.parse(saved) : [];
    });



    const [resetCodeLangSelection, setResetCodeLangSelection] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [templateOptions, setTemplateOptions] = useState([]);
    const { campaignId } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [showEmailPopup, setShowEmailPopup] = useState(false);
    const { triggerNotification } = useNotification();
    const authFetch = useAuthFetch(); // Initialisation de authFetch

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const fetchCampaignDetails = useCallback(async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/campaign/fetch/${campaignId}`); // Remplacement de fetch par authFetch
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
            const data = await response.json();
            setCampaign(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des détails de la campagne:", error);
        }
    }, [authFetch, campaignId]);

    const fetchCodeLangs = useCallback(async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/codelang/list?campaign_uuid=${campaignId}`); // Remplacement de fetch par authFetch
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
            const data = await response.json();
            setCodeLangs(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des déclinaisons:", error);
        }
    }, [authFetch, campaignId]);

    const fetchBatchs = useCallback(async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/batch/list?campaign_uuid=${campaignId}`); // Remplacement de fetch par authFetch
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
            const data = await response.json();
            setBatchs(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des batchs:", error);
        }
    }, [authFetch, campaignId]);

    const fetchTemplates = useCallback(async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/template/list?campaign_uuid=${campaignId}`); // Remplacement de fetch par authFetch
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
            const data = await response.json();
            setTemplates(data);

            setTemplateOptions(data.map(template => ({
                value: template.uuid,
                label: template.name
            })));
        } catch (error) {
            console.error("Erreur lors de la récupération des batchs:", error);
        }
    }, [authFetch, campaignId]);

    useEffect(() => {
        fetchCampaignDetails();
        fetchCodeLangs();
        fetchBatchs();
        fetchTemplates();
    }, [fetchCampaignDetails, fetchCodeLangs, fetchBatchs, fetchTemplates]);


    const handleCodeLangSelectionChange = (selectedUuids) => {
        setSelectedCodeLangs(selectedUuids);
    };


    const handleNavigateToAddCodeLang = () => {
        navigate(`/campagnes/${campaignId}/declinaison`);
    };


    const handleNavigateToAddBatch = () => {
        navigate(`/campagnes/${campaignId}/ajouter-batch`);
    };

    const handleNavigateToAddTemplate = () => {
        navigate(`/campagnes/${campaignId}/ajouter-template`);
    };

    const handleNavigateToEditCampaign = () => {
        navigate(`/campagnes/${campaignId}/modifier`);
    };

    const handleNavigateToEditTemplate = (templateId) => {
        navigate(`/campagnes/${campaignId}/edit-template/${templateId}`);
    };


    const handleLinkTemplate = async () => {
        setIsLoading(true);
        if (selectedTemplate === 'delete-link') {
            await handleDeleteLink();
        } else if (selectedTemplate === 'delete-codeLang') {
            await handleDeleteCodeLang();
        } else {
            await handleLink();
        }
    };


    const handleLink = async () => {
        if (selectedTemplate && selectedCodeLangs.length > 0) {
            try {
                const response = await authFetch(`${config.API_URL}/emailing/template/link-to-codelangs`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        template_uuid: selectedTemplate,
                        code_lang_uuids: selectedCodeLangs,
                    }),
                });

                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(data.error || 'Une erreur est survenue lors de la liaison');
                }

                triggerNotification({
                    type: 'success',
                    content: response.message || 'Liaison entre le template et les déclinaisons réussie.',
                    duration: 3000,
                    persistent: false
                });
                setResetCodeLangSelection(true);
                await fetchCodeLangs();
            } catch (error) {
                triggerNotification({
                    type: 'error',
                    content: error.message,
                    duration: 3000,
                    persistent: false
                });
                console.error("Erreur:", error);
            }finally {
                setIsLoading(false);
            }
        }
    };

    const handleDownloadTranslationFile = async () => {
        if (selectedCodeLangs.length === 0) {
            triggerNotification({
                type: 'error',
                content: "Aucun CodeLang sélectionné.",
                duration: 3000,
                persistent: false
            });
            return;
        }

        setIsLoading(true);
        try {
            const url = `${config.API_URL}/emailing/placeholder/translation-file/${campaignId}`;
            const response = await authFetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ codeLangUuids: selectedCodeLangs })
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de la génération du fichier de traduction');
            }

            const data = await response.json();
            downloadExcel(data.file, data.filename);
        } catch (error) {
            console.error('Erreur lors du téléchargement du fichier :', error);
            triggerNotification({
                type: 'error',
                content: error.message || 'Erreur lors de la génération du fichier de traduction',
                duration: 3000,
                persistent: false
            });
        } finally {
            setIsLoading(false);
        }
    };




    const handleUploadTranslationFile = async () => {
        if (!file) {
            triggerNotification({
                type: 'error',
                content: "Veuillez sélectionner un fichier Excel.",
                duration: 3000,
                persistent: false
            });
            return;
        }
        setIsLoading(true);
        const reader = new FileReader();

        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const formattedData = { emails_objects: [], codelangs: {} };

            workbook.SheetNames.forEach(sheetName => {
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                if (sheetName === 'Emails Objects') {
                    // Traitement spécifique pour la feuille 'Emails Objects'
                    formattedData.emails_objects = jsonData.map(row => ({
                        declinations: row['Declinations'],
                        object: row['Object']
                    }));
                } else {
                    // Traitement pour les autres feuilles
                    formattedData.codelangs[sheetName] = jsonData.map(row => ({
                        name: row['Placeholders'],
                        value: row['Value']
                    }));
                }
            });

            try {
                const response = await authFetch(`${config.API_URL}/emailing/placeholder/update-multiple/${campaignId}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formattedData)
                });

                if (!response.ok) {
                    const responseData = await response.json();
                    throw new Error(responseData.error || 'Erreur lors de l’envoi des données.');
                }

                const responseData = await response.json();
                triggerNotification({
                    type: 'success',
                    content: responseData.message || 'Traductions ajoutées avec succès',
                    duration: 3000,
                    persistent: false
                });
            } catch (error) {
                triggerNotification({
                    type: 'success',
                    content: error.message || 'Erreur lors de la communication avec l’API.',
                    duration: 3000,
                    persistent: false
                });
            } finally {
                setIsLoading(false);
            }
        };

        reader.readAsBinaryString(file);
    }


    const downloadExcel = (encodedContent, filename) => {
        const link = document.createElement('a');
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${encodedContent}`;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDeleteLink = async () => {
        const confirmDelete = window.confirm("Attention ! Les traductions des déclinaisons sélectionnées seront supprimées définitivement. Assurez-vous de télécharger le modèle de traduction avant la suppression.\n\nContinuez malgré tout ?");
        if (confirmDelete) {
            try {
                for (const codeLangUuid of selectedCodeLangs) {
                    const response = await authFetch(`${config.API_URL}/emailing/placeholder/delete/${codeLangUuid}`, {
                        method: 'DELETE'
                    });

                    if (!response.ok) {
                        const data = await response.json();
                        throw new Error(data.error || 'Une erreur est survenue lors de la suppression de la liaison');
                    }
                }

                triggerNotification({
                    type: 'success',
                    content: 'Supression de la liaison réussie',
                    duration: 3000,
                    persistent: false
                });
                setResetCodeLangSelection(true);
                await fetchCodeLangs();
            } catch (error) {
                triggerNotification({
                    type: 'success',
                    content: error.message,
                    duration: 3000,
                    persistent: false
                });
                console.error("Erreur:", error);
            }finally {
                setIsLoading(false);
            }
        }else{
            setIsLoading(false);
        }

    };

    const handleOpenEmailPopup = () => {
        setShowEmailPopup(true);
    };

    const handleCloseEmailPopup = () => {
        setShowEmailPopup(false);
    };

    const handleSendEmails = async (emails) => {
        setIsLoading(true);

        const emailData = {
            emails: emails,
            codelangs_uuid: selectedCodeLangs,
        };
        try {
            const response = await authFetch(`${config.API_URL}/emailing/email/bat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(emailData),
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || 'Une erreur est survenue lors de l’envoi ');
            }
            if (data.errors && data.errors.length > 0) {
                triggerNotification({
                    type: 'error',
                    content: data.errors.join(', '),
                    duration: 3000,
                    persistent: false
                });
            }
            if (data.success) {
                triggerNotification({
                    type: 'success',
                    content: `Emails envoyés avec succès : ${data.success}`,
                    duration: 3000,
                    persistent: false
                });
            }

        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message || 'Erreur lors de la communication avec l’API.',
                duration: 3000,
                persistent: false
            });
        } finally {
            setIsLoading(false);
            setShowEmailPopup(false);
        }
    };

    const handleToggleBatchStatus = async (batchId, currentStatus) => {
        const newStatus = currentStatus === 'ready' ? 'created' : 'ready';

        try {
            const response = await authFetch(`${config.API_URL}/emailing/batch/status/update/${batchId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de la mise à jour du statut du batch');
            }

            setBatchs(batchs.map(batch => batch.uuid === batchId ? { ...batch, status: newStatus } : batch));
            triggerNotification({
                type: 'success',
                content: 'Statut du batch mis à jour avec succès.',
                duration: 3000,
                persistent: false
            });
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut du batch:", error);
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false
            });
        }
    };

    const handleDeleteCodeLang = async () => {
        setIsLoading(true);
        try {
            for (const codeLangUuid of selectedCodeLangs) {
                const response = await authFetch(`${config.API_URL}/emailing/codelang/delete/${codeLangUuid}`, {
                    method: 'DELETE'
                });

                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(data.error || 'Une erreur est survenue lors de la suppression du codeLang');
                }
            }

            triggerNotification({
                type: 'success',
                content: 'Suppression des codeLangs réussie',
                duration: 3000,
                persistent: false
            });
            setResetCodeLangSelection(true);
            await fetchCodeLangs();
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false
            });
            console.error("Erreur lors de la suppression:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSaveSelection = (name) => {
        const newSavedSelections = [...savedSelections, { name, selection: selectedCodeLangs }];
        setSavedSelections(newSavedSelections);
        localStorage.setItem('savedSelections', JSON.stringify(newSavedSelections));
    };

    const handleDeleteSavedSelection = (index) => {
        const newSavedSelections = savedSelections.filter((_, i) => i !== index);
        setSavedSelections(newSavedSelections);
        localStorage.setItem('savedSelections', JSON.stringify(newSavedSelections));
    };


    return (
        <div className="campaign-detail">
            {isLoading && <Loader />}


            <Breadcrumb />


            {campaign && (
                <div>
                    <h1>Campagne  {campaign.name} du {convertToDDMMYYYY(campaign.theoric_scheduled_date)}</h1>
                    <Button onClick={handleNavigateToEditCampaign}>Modifier la campagne</Button>
                    <h2>Détails</h2>
                    <p>Nom: {campaign.name}</p>
                    <p>Date théorique d'envoi: {convertToDDMMYYYY(campaign.theoric_scheduled_date)}</p>
                    <p>Date de création: {convertToDDMMYYYYWithTime(campaign.create_date)}</p>
                    <Status status={campaign.status} />
                </div>
            )}

            <h2>Templates</h2>
            <Button onClick={handleNavigateToAddTemplate}>Ajouter un template</Button>
            <TemplatesList templates={templates} handleNavigateToEditTemplate={handleNavigateToEditTemplate} />


            <h2>Déclinaisons</h2>

            <Button onClick={handleNavigateToAddCodeLang}>Ajouter des déclinaisons</Button>
            {selectedCodeLangs.length > 0 && (
                <Button onClick={handleDownloadTranslationFile} disabled={isLoading}>
                    {isLoading ? 'Chargement...' : 'Télécharger le modèle de traduction'}
                </Button>
            )}

            {selectedCodeLangs.length > 0 && (
                <>
                    <Select
                        value={selectedTemplate}
                        onChange={(e) => setSelectedTemplate(e.target.value)}
                        options={[
                            { value: '', label: 'Sélectionnez un template' },
                            ...templateOptions,
                            { value: 'delete-link', label: 'Supprimer la liaison de template' },
                            { value: 'delete-codeLang', label: 'Supprimer la déclinaison' }
                        ]}
                    />
                    <Button onClick={handleLinkTemplate} disabled={isLoading || selectedTemplate === ''}>
                        {isLoading ? 'Chargement...' :
                            selectedTemplate === 'delete-link' ? 'Supprimer la Liaison' :
                                selectedTemplate === 'delete-codeLang' ? 'Supprimer Déclinaison' :
                                    'Lier le Template Sélectionné'
                        }
                    </Button>



                    <Button onClick={handleOpenEmailPopup}>Envoyer BAT</Button>

                </>
            )}
            {showEmailPopup && (
                <EmailPopup
                    onClose={handleCloseEmailPopup}
                    onSend={handleSendEmails}
                />
            )}


            <div>
                <h2>Importez les traductions</h2>
                <p>Remplissez le modèle de traduction puis uploadez le.</p>
                <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
                <Button onClick={handleUploadTranslationFile} disabled={!file || isLoading}>
                    {isLoading ? 'Chargement...' : 'Uploader les traductions'}
                </Button>
            </div>

            <CodeLangList
                codeLangs={codeLangs}
                campaignId={campaignId}
                onSelectionChange={handleCodeLangSelectionChange}
                resetSelection={resetCodeLangSelection}
                setResetSelection={setResetCodeLangSelection}
                savedSelections={savedSelections}
                onSaveSelection={handleSaveSelection}
                onDeleteSavedSelection={handleDeleteSavedSelection}
            />



            <h2>Batchs</h2>
            <Button onClick={handleNavigateToAddBatch}>Ajouter un batch</Button>
            <BatchList
                campaignId={campaignId}
                batchs={batchs}
                onToggleStatus={handleToggleBatchStatus}
            />
        </div>
    );
};

export default Campaign;
