import React, { useState } from 'react';
import CampaignForm from '../../../components/organisms/CampaignForm/CampaignForm';
import config from "../../../config";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useAuthFetch } from "../../../hooks/useAuthFetch"; // Ajout de l'import

const AddCampaign = () => {
    const navigate = useNavigate();
    const authFetch = useAuthFetch(); // Initialisation de authFetch
    const [campaignName, setCampaignName] = useState('');
    const [theoricSendDate, setTheoricSendDate] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleFormSubmit = async () => {
        setMessage('');
        setError('');

        const campaignData = {
            name: campaignName,
            theoric_scheduled_date: theoricSendDate,
        };

        try {
            const response = await authFetch(`${config.API_URL}/emailing/campaign/add`, { // Remplacement de fetch par authFetch
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(campaignData),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de l’ajout de la campagne');
            }

            const data = await response.json();
            setMessage(data.message || 'La campagne a été ajoutée avec succès.');
            setCampaignName('');
            setTheoricSendDate('');
            setTimeout(() => {
                navigate(`/campagnes`);
            }, 800);
        } catch (error) {
            setError(error.message || 'Erreur lors de la communication avec l’API.');
        }
    };


    const clearMessages = () => {
        setMessage('');
        setError('');
    };

    return (
        <div>
            <Breadcrumb />
            <h1>Ajouter une Campagne</h1>
            <CampaignForm
                campaignName={campaignName}
                setCampaignName={setCampaignName}
                theoricSendDate={theoricSendDate}
                setTheoricSendDate={setTheoricSendDate}
                onSubmit={handleFormSubmit}
                errorMessage={error}
                successMessage={message}
                clearMessages={clearMessages}
            />
        </div>
    );
};

export default AddCampaign;
